<template>
	<div>
		<el-dialog class="dialog" :title="dialogObj.unitName + '历史报警'" :visible.sync="visiable">
			<div class="flex" style="flex-direction: column;height: 100%;">
				<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
					<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
						<el-row class="flex-1">
							<el-form-item label="报警时间：">
								<el-col :span="10">
									<el-date-picker v-model="searchParm.startTime" value-format="yyyy-MM-dd hh:mm:ss"
										type="datetime" placeholder="请选择日期"></el-date-picker>
								</el-col>
								<el-col :span="2" style="text-align: center;"><span>-</span></el-col>
								<el-col :span="10">
									<el-date-picker v-model="searchParm.endTime" value-format="yyyy-MM-dd hh:mm:ss"
										type="datetime" placeholder="请选择日期"></el-date-picker>
								</el-col>
							</el-form-item>
						</el-row>
						<div>
							<el-button type="primary" @click="list()">查询</el-button>
							<el-button plain @click="clearSearch()">重置</el-button>
						</div>
					</el-form>
				</sm-card>
				<div class="tableModule" ref="tableContainerss">
					<div class="title">
						<div class="title-left">
							<i class="el-icon-s-operation"></i>
							<span>报警列表</span>
						</div>
					</div>
					<div class="tableContainer">
						<div class="table">
							<div>
								<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
									:header-cell-style="{ 'background-color': '#f9fafc' }" :cell-style="{ padding: 0 }"
									style="margin-bottom: 20px;">
									<el-table-column align="center" label="序号" show-overflow-tooltip>
										<template slot-scope="scope">
											<div>{{ scope.$index + 1 }}</div>
										</template>
									</el-table-column>
									<!-- <el-table-column prop="unitId" align="center" label="单体id" show-overflow-tooltip></el-table-column> -->
									<el-table-column prop="time" align="center" label="历史报警时间"
										show-overflow-tooltip></el-table-column>
									<el-table-column align="center" label="企业查看权限">
										<template slot-scope="scope">
											<div slot="reference" class="name-wrapper">
												<el-tag v-if="scope.row.isEffective == 1" type="success"
													size="medium">已开放</el-tag>
												<el-tag v-else size="medium" type="warning">未开放</el-tag>
											</div>
										</template>
									</el-table-column>
									<el-table-column align="center" label="操作">
										<template slot-scope="scope">
											<el-button type="text" @click="openMeas(scope.row)">查看图谱</el-button>
											<el-button type="text" @click="openThree(scope.row)">查看3D</el-button>
											<el-button type="text" @click="setUp(scope.row)">设置权限</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
						<div class="pagination">
							<el-pagination style="float: right;" @current-change="handleCurrentChange"
								:current-page.sync="searchParm.current" :page-size="searchParm.size"
								layout="total, prev, pager, next,jumper" :total="tableData.total"></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
		<chats ref="chats" :row="tbRow" :rows="tbRows"></chats>
		<threeDchats ref="threeDchats" :row="thRow"></threeDchats>
	</div>
</template>
<script>
	import smCard from '@c/smCard.vue';
	import {
		dialogMixin
	} from '@m/dialogMixin';
	import configApi from '@config/configApi';
	import caches from '@cache/cache.js';
	import threeDchats from '../../monitoring/component/threeDchats.vue';
	import chats from '../../monitoring/component/chats.vue';
	export default {
		components: {
			chats,
			smCard,
			threeDchats
		},
		props: {
			dialogObj: Object
		},
		mixins: [dialogMixin],
		data() {
			return {
				tableHeight: 500,
				tableData: {
					records: []
				},
				searchParm: {
					size: 20,
					current: 1
				},
				thRow: '',
				tbRow: null,
				tbRows: null,
			};
		},
		updated() {
			window.onmessage = (event) => {
				if (!event.data.data) return;
				if (event.data.data.type == 6) {
					//单体进图谱
					this.getChats(event.data.data.row, event.data.data.rows);
				}
				// 根据上面制定的结构来解析iframe内部发回来的数据				
			}
		},
		methods: {
			//打开弹窗
			open() {
				this.list();
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					size: 20,
					current: 1
				};
			},

			//展示数据
			list() {
				this.searchParm.unitId = this.dialogObj.unitId;
				this.$get('/backend-api/type/alarm/time-list', this.searchParm).then(res => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
			//关闭弹窗
			close() {
				this.visiable = false;
			},
			openMeas(e) {
				this.$router.push({
					name: 'platformalarmMeas',
					params: e
				});
			},
			setUp(e) {
				this.$alert(`当前为${e.isEffective == 0 ? '未开放' : '已开放'},是否设置为${e.isEffective == 0 ? '开放' : '不开放'}`,
					'设置企业查看权限', {
						confirmButtonText: '确定',
						callback: action => {
							if (action == 'confirm') {
								this.setType(e.isEffective, e.id);
							}
						}
					});
			},
			setType(e, id) {
				if (e == 0) {
					e = 1;
				} else {
					e = 0;
				}
				let param = {
					effective: e,
					id: id
				};
				this.$post('/backend-api/type/alarm/effect', param).then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					this.list();
					this.$message({
						message: res.msg,
						type: 'success'
					});
				});
			},
			//打开3D弹框
			openThree(item) {

				if (item.typeId && item.typeId != '') {
					let fileUrl = ''
					if (item.isType == 1) {
						fileUrl = '/html/index.html'
					} else {
						fileUrl = '/html/indexOld.html'
					}

					var url = {
						url: fileUrl + '?token=' + caches.token.get() +
							'&unitId=' +
							item.unitId +
							'&typeId=' +
							item.typeId +
							'&url=' +
							this.baseURL +
							'&imgUrl=' +
							this.photoURL +
							'&times=' +
							encodeURIComponent(item.time)
					};
					this.$refs.threeDchats.dialogVisible = true;
					this.$refs.threeDchats.fullscreen = true;
					this.thRow = Object.assign(item, url);
				} else {
					this.$message.warning('3D模型制作中');
				}
			},

			// 打开图表弹框
			getChats(j, rows) {
				this.$refs.chats.dialogVisible = true;
				this.$refs.chats.fullscreen = true;
				this.tbRow = j;
				this.tbRows = rows;
				this.theeShow = true;
			}
		},
		beforeDestroy() {
			this.visiable = false;
		}
	};
</script>
<style lang="scss" scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}

		::v-deep.el-dialog {
			width: 80%;
		}

		.tableModule {
			padding: 20px;
		}
	}
</style>